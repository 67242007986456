.searchForm {
    display: flex;
    align-items: center;
  }
  
  .searchInput {
    flex: 1;
    padding: 10px;
    border: none !important;
    font-size: 16px;
  }
  
  .searchButton {
    margin-left: 10px;
    padding: 10px 20px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .searchButton:hover {
    background-color: #45a049;
  }
  