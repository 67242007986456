@import '../../styles/customMediaQueries.css';

.seoLinks {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;

  @media (--viewportSmall) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (--viewportMedium) {
    grid-template-columns: repeat(4, 1fr);
  }

  & > span {
    color: #51565c;
    font-size: 15px;
    line-height: 130%;
    font-weight: var(--fontWeightRegular);
    margin: 0;
    padding: 0;
    cursor: pointer;
    transition: var(--transitionStyle);

    &:hover {
      cursor: pointer;
      transition: var(--transitionStyle);
      color: var(--marketplaceColorDark);
    }
  }
}

.categoryDetailPage {
  background-color: var(--marketplaceColorLightBg);
  max-width: var(--contentMaxWidthPages);
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 24px 50px 24px;
  min-height: 40vh;

  @media (--viewportMedium) {
    padding: 50px 24px;
  }

  & > h2 {
    color: var(--colorText);
    font-size: 50px;
    font-weight: var(--fontWeightBlack);
    line-height: 100%;
    padding: 0;
    margin: 0 0 10px 0;

    @media (max-width: 767px) {
      font-size: 40px;
    }
  }

  & > p {
    color: var(--colorText);
    font-size: 20px;
    line-height: 130%;
    font-weight: var(--fontWeightRegular);
    padding: 0;
    max-width: 70%;
    width: 100%;
    text-align: center;
    margin: 0 auto;
  }

  & .categorySearch {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: var(--contentMaxWidth);
    margin: 24px auto 0;
    z-index: 1;

    @media (--viewportSmall) {
      background-color: var(--colorText);
      padding: 10px 10px 10px 20px;
      border-radius: var(--borderRadiusFull);
      flex-direction: row;
    }

    & .searchBtn {
      width: auto;
      min-width: 150px;
      min-height: 50px;
      margin: 15px 0 0;

      @media (--viewportSmall) {
        margin: 0;
      }
    }
  }
}

.categoryPageWrapper {
  & :global(.slick-slider) {
    margin: 0 -10px;

    &.categoryListingSlider {
      margin-bottom: 50px;
    }

    & :global(.slick-list) {
      & :global(.slick-track) {
        display: flex;
        align-items: stretch;
      }

      & :global(.slick-slider) {
        margin: 0;

        & :global(.slick-list) {
          padding: 0 !important;
        }

        & :global(.slick-slide) {
          padding: 0 !important;

          & :global(.slick-arrow) {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 100px;
            background-color: var(--colorText);
            z-index: 1;
            box-shadow: var(--boxShadow);
            transition: var(--transitionStyle);

            &:hover {
              transition: var(--transitionStyle);
              transform: scale(1.2);
            }
          }

          & :global(.slick-arrow.slick-disabled) {
            opacity: 0.5;
            cursor: not-allowed;
          }

          & :global(.slick-prev) {
            left: 0px;
          }

          & :global(.slick-prev:before) {
            content: ' ';
            background-image: url(data:image/svg+xml;base64,PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KDTwhLS0gVXBsb2FkZWQgdG86IFNWRyBSZXBvLCB3d3cuc3ZncmVwby5jb20sIFRyYW5zZm9ybWVkIGJ5OiBTVkcgUmVwbyBNaXhlciBUb29scyAtLT4KPHN2ZyB3aWR0aD0iODAwcHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KDTxnIGlkPSJTVkdSZXBvX2JnQ2FycmllciIgc3Ryb2tlLXdpZHRoPSIwIi8+Cg08ZyBpZD0iU1ZHUmVwb190cmFjZXJDYXJyaWVyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KDTxnIGlkPSJTVkdSZXBvX2ljb25DYXJyaWVyIj4gPHBhdGggZD0iTTYuNzMxNzcgMTAuNjQwMkM2LjM3ODIxIDEwLjIxNTkgNi40MzU1MyA5LjU4NTM3IDYuODU5ODEgOS4yMzE4QzcuMjg0MDkgOC44NzgyNCA3LjkxNDY1IDguOTM1NTYgOC4yNjgyMiA5LjM1OTg0TDEzLjI2ODIgMTUuMzU5OEMxMy42MjE4IDE1Ljc4NDEgMTMuNTY0NSAxNi40MTQ3IDEzLjE0MDIgMTYuNzY4MkMxMi43MTU5IDE3LjEyMTggMTIuMDg1MyAxNy4wNjQ1IDExLjczMTggMTYuNjQwMkw2LjczMTc3IDEwLjY0MDJaIiBmaWxsPSIjMDAwMDAwIi8+IDxwYXRoIGQ9Ik04LjI2ODIyIDEwLjY0MDJDNy45MTQ2NSAxMS4wNjQ1IDcuMjg0MDkgMTEuMTIxOCA2Ljg1OTgxIDEwLjc2ODJDNi40MzU1MyAxMC40MTQ3IDYuMzc4MjEgOS43ODQxMiA2LjczMTc3IDkuMzU5ODRMMTEuNzMxOCAzLjM1OTg0QzEyLjA4NTMgMi45MzU1NiAxMi43MTU5IDIuODc4MjQgMTMuMTQwMiAzLjIzMThDMTMuNTY0NSAzLjU4NTM3IDEzLjYyMTggNC4yMTU5MyAxMy4yNjgyIDQuNjQwMjFMOC4yNjgyMiAxMC42NDAyWiIgZmlsbD0iIzAwMDAwMCIvPiA8L2c+Cg08L3N2Zz4=);
            background-repeat: no-repeat;
            width: 20px;
            height: 20px;
            display: inline-block;
            background-size: 100%;
          }

          & :global(.slick-next) {
            right: 0px;
          }

          & :global(.slick-next:before) {
            content: ' ';
            background-image: url(data:image/svg+xml;base64,PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KDTwhLS0gVXBsb2FkZWQgdG86IFNWRyBSZXBvLCB3d3cuc3ZncmVwby5jb20sIFRyYW5zZm9ybWVkIGJ5OiBTVkcgUmVwbyBNaXhlciBUb29scyAtLT4KPHN2ZyB3aWR0aD0iODAwcHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB0cmFuc2Zvcm09InJvdGF0ZSgxODApIj4KDTxnIGlkPSJTVkdSZXBvX2JnQ2FycmllciIgc3Ryb2tlLXdpZHRoPSIwIi8+Cg08ZyBpZD0iU1ZHUmVwb190cmFjZXJDYXJyaWVyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KDTxnIGlkPSJTVkdSZXBvX2ljb25DYXJyaWVyIj4gPHBhdGggZD0iTTYuNzMxNzcgMTAuNjQwMkM2LjM3ODIxIDEwLjIxNTkgNi40MzU1MyA5LjU4NTM3IDYuODU5ODEgOS4yMzE4QzcuMjg0MDkgOC44NzgyNCA3LjkxNDY1IDguOTM1NTYgOC4yNjgyMiA5LjM1OTg0TDEzLjI2ODIgMTUuMzU5OEMxMy42MjE4IDE1Ljc4NDEgMTMuNTY0NSAxNi40MTQ3IDEzLjE0MDIgMTYuNzY4MkMxMi43MTU5IDE3LjEyMTggMTIuMDg1MyAxNy4wNjQ1IDExLjczMTggMTYuNjQwMkw2LjczMTc3IDEwLjY0MDJaIiBmaWxsPSIjMDAwMDAwIi8+IDxwYXRoIGQ9Ik04LjI2ODIyIDEwLjY0MDJDNy45MTQ2NSAxMS4wNjQ1IDcuMjg0MDkgMTEuMTIxOCA2Ljg1OTgxIDEwLjc2ODJDNi40MzU1MyAxMC40MTQ3IDYuMzc4MjEgOS43ODQxMiA2LjczMTc3IDkuMzU5ODRMMTEuNzMxOCAzLjM1OTg0QzEyLjA4NTMgMi45MzU1NiAxMi43MTU5IDIuODc4MjQgMTMuMTQwMiAzLjIzMThDMTMuNTY0NSAzLjU4NTM3IDEzLjYyMTggNC4yMTU5MyAxMy4yNjgyIDQuNjQwMjFMOC4yNjgyMiAxMC42NDAyWiIgZmlsbD0iIzAwMDAwMCIvPiA8L2c+Cg08L3N2Zz4=);
            background-repeat: no-repeat;
            width: 20px;
            height: 20px;
            display: inline-block;
            background-size: 100%;
          }
        }
      }
    }

    & :global(.slick-slide) {
      padding: 10px;
      height: auto;

      & > div {
        height: 100%;

        & > div {
          height: 100%;
        }
      }
    }

    & :global(.slick-arrow) {
      position: absolute;
      z-index: 1;
      width: 40px;
      height: 40px;
      border-radius: 100px;
    }

    & :global(.slick-arrow.slick-disabled) {
      opacity: 0.5;
      cursor: not-allowed;
    }

    & :global(.slick-prev) {
      left: -40px;
    }

    & :global(.slick-next) {
      right: -40px;
    }

    & :global(.slick-prev:before),
    & :global(.slick-next:before) {
      display: none;
    }

    & :global(.slick-dots > li) {
      margin: 0;
    }

    & :global(.slick-dots > li > button) {
      width: 8px;
      height: 8px;
      cursor: pointer;
      border-radius: 50%;
      background-color: var(--colorText);
      border: none;
      margin: 0 6px 0 0;
    }

    & :global(.slick-dots > li.slick-active > button) {
      background-color: var(--marketplaceColor);
    }

    & :global(.slick-dots > li > button::before) {
      display: none;
    }
  }
}

.contentWidth {
  max-width: var(--contentMaxWidthPages);
  width: 100%;
  margin: 0 auto;
  padding: 0 24px;
}

.breadcrumb {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 15px 0;

  & > span {
    font-size: 14px;
    color: var(--colorTextDark);
    cursor: pointer;

    &:last-of-type {
      font-weight: var(--fontWeightBlack);
    }
  }

  & > svg {
    width: 18px;
    height: 18px;
  }
}

.popularCategorySec {
  margin-bottom: 70px;
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    margin-bottom: 50px;
  }

  & > h2 {
    color: var(--colorText);
    font-size: 35px;
    line-height: 100%;
    font-weight: var(--fontWeightBlack);
    margin: 0 0 15px 0;
    padding: 0;
  }

  & > p {
    color: var(--colorText);
    font-size: 20px;
    line-height: 130%;
    font-weight: var(--fontWeightRegular);
    margin: 0 0 15px 0;
    padding: 0;
    text-align: justify;
    width: 76%;
  }
}

.campingListing {
  margin-bottom: 24px;

  & :global(.slick-arrow) {
    top: 50%;
  }

  & .campingListingImg {
    padding-bottom: 66.66%;
    position: relative;
    overflow: hidden;
    border-radius: var(--borderRadiusLarge);

    & > img {
      max-width: 100%;
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    & > h2 {
      color: var(--colorText);
      font-size: 30px;
      font-weight: var(--fontWeightBlack);
      line-height: 120%;
      padding: 0;
      margin: 0 0 4px 0;
    }
  }
}

.faqQuestionBlock {
  margin: 24px 0 15px;

  & > h3 {
    font-weight: var(--fontWeightBold);
    color: var(--colorTextDark);
    font-size: 18px;
    line-height: 100%;
    margin: 0 0 15px 0;
    padding: 0;
  }

  & > p {
    font-size: 15px;
    color: var(--colorTextDark);
    margin: 0;
    word-wrap: break-word;
    text-align: justify;
  }

  & ol,
  & ul {
    margin: 10px 0;

    & li {
      font-size: 15px;
      color: var(--colorTextDark);
      margin: 0 0 8px 0;
      word-wrap: break-word;
      text-align: justify;

      & > strong {
        color: var(--colorGrey700);
      }
    }
  }

  & ul {
    list-style-type: disc;
    padding-left: 40px;
  }
}

.otherinfoBlock {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }

  &:first-of-type {
    margin-top: 24px;
  }

  &:not(:last-of-type) {
    border-bottom: solid 1px #97b38e;
    padding-bottom: 24px;
    margin-bottom: 24px;
  }

  & .otherinfoLeftBlock {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    @media (--viewportMedium) {
      width: 30%;
      margin: 0;
    }

    & > svg {
      margin-right: 10px;

      & path {
        stroke: var(--colorTextDark);
      }
    }

    & > h3 {
      font-weight: var(--fontWeightBlack);
      color: var(--colorTextDark);
      font-size: 24px;
      line-height: 100%;
      margin: 0;
      padding: 0;
    }
  }

  & .otherinfoRightBlock {
    width: 100%;
    font-size: 15px;
    color: #51565c;
    margin: 0;
    word-wrap: break-word;
    text-align: justify;

    & > div {
      & > p {
        margin-top: 0;
      }
    }

    @media (--viewportMedium) {
      width: 70%;
    }

    & > p {
      font-size: 15px;
      color: #51565c;
      margin: 0;
      word-wrap: break-word;
      text-align: justify;

      & > div {
        & > p {
          text-align: left;
          margin-top: 0;
        }
      }
    }

    & > h4 {
      font-weight: var(--fontWeightBlack);
      color: var(--colorTextDark);
      font-size: 18px;
      line-height: 100%;
      margin: 0 0 10px 0;
      padding: 0;

      &:not(:first-of-type) {
        margin-top: 24px;
      }
    }
  }
}

.categoryMapSection {
  margin-bottom: 50px;

  & > div {
    & > div {
      position: relative;
      width: 100%;
      border-radius: var(--borderRadiusLarge);
      height: 50vh;

      & canvas {
        width: 100% !important;
      }
    }
  }
}

.spinner {
  display: flex;
  justify-content: center;
  margin: 191px;
}

.faqSec {
  padding: 0px;
  float: left;
  width: 100%;
  background: #9cc78f;

  @media (--viewportMedium) {
    padding: 50px 0px;
  }

  & .contentWidth {
    padding: 0;
    display: flex;
    flex-direction: column;

    & > h2 {
      color: var(--colorText);
      font-size: 35px;
      line-height: 100%;
      font-weight: var(--fontWeightBlack);
      margin: 0 0 15px 0;
      padding: 0;
    }

    & .faqCardSec {
      display: grid;
      margin-top: 15px;

      @media (--viewportMedium) {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 56px;
      }

      & .faqCard {
        cursor: pointer;
        padding: 15px 0;
        border-bottom: solid 2px var(--colorText);

        &:first-child {
          border-top: solid 2px var(--colorText);
        }

        @media (--viewportMedium) {
          padding: 25px 0;

          &:nth-child(2) {
            border-top: solid 2px var(--colorText);
          }
        }

        &.activeFAQ {
        }

        & .faqTitle {
          display: flex;
          align-items: center;
          justify-content: space-between;

          & .faqHeading {
            padding-right: 16px;
            font-size: 20px;
            font-weight: var(--fontWeightBold);
            color: var(--marketplaceColorDark);
          }

          & .faqIcon {
            cursor: pointer;

            & > svg {
              & path,
              & rect,
              & line {
                stroke: var(--colorText);
              }
            }
          }
        }

        & .faqDescription {
          padding-top: 20px;

          & .faqContent {
            width: 100%;

            @media (--viewportMedium) {
              max-width: 90%;
            }

            & > p {
              font-size: 20px;
              color: var(--colorText);
              line-height: 130%;
              font-weight: var(--fontWeightRegular);
              margin: 0;
              text-align: justify;

              @media (--viewportMedium) {
                font-size: 15px;
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
}

.popularListingSec {
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;

  & .popularListings {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;

    @media (--viewportSmall) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (--viewportMedium) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (--viewportLarge) {
      grid-template-columns: repeat(4, 1fr);
    }

    & :global(.slick-slider) {
      margin: 0px;
    }

    & :global(.slick-slide) {
      padding: 0px !important;
    }
  }
}

.showMoreSec {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 45px;
}

.showBtn {
  composes: buttonDefault from global;
  width: auto;
  height: auto;
  min-width: 150px;
  min-height: auto;
  padding: 10px 20px;
  line-height: 100%;
  font-size: 16px;
  font-weight: var(--fontWeightBold);
  background-color: var(--marketplaceColorDark);
  border-color: var(--marketplaceColorDark);

  @media (--viewportMedium) {
    font-size: 19px;
  }
}

.sliderItem {
  padding-bottom: 100%;
  position: relative;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: var(--transitionStyle);

  & > img {
    position: absolute;
    width: 100%;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
    transition: var(--transitionStyle);
  }

  & > h2 {
    color: var(--colorText);
    font-size: 30px;
    line-height: 130%;
    font-weight: var(--fontWeightBlack);
    margin: 0 0 10px 0;
    padding: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
    z-index: 1;
    text-shadow: 0px 0px 8px #000000a3;
  }
}

.searchBtnDisabled {
  width: auto;
  min-width: 150px;
  min-height: 50px;
  margin: 15px 0 0;
  cursor: not-allowed;

  @media (--viewportSmall) {
    margin: 0;
  }
}
